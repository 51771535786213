import React from 'react';

import * as styles from '../../styles/modules/form-input.module.scss';

type InputProps = {
    label: string;
    errors: any;
    variant?: 'dark';
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const TextArea = ({ label, errors, name, variant, ...props }: InputProps) => {
    return (
        <div className={[styles.input, variant ? styles.dark : ''].join(' ')}>
            <label htmlFor={name}>{label}</label>
            <textarea name={name} {...props}></textarea>
            { errors && <div className={styles.errors}>{errors}</div>}
        </div>
    );
};

export default TextArea;
