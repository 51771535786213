import * as Yup from 'yup';

export default Yup.object({
    name: Yup.string()
        .max(50, 'Must be 50 characters or less')
        .required('Required'),
    phone: Yup.string()
        .matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, { message: 'Invalid phone number' }),
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
    body: Yup.string()
        .max(300, 'Must be fewer than 300 characters')
        .required('Required')
});
