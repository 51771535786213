import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Button } from 'react-bootstrap';

// Components
import Input from './input';
import TextArea from './textarea';

import * as styles from '../../styles/modules/form.module.scss';
import validationSchema from './validationSchema';

type FormProps = {
    variant?: 'dark';
};

const Form = ({ variant }: FormProps) => {
    const [ buttonText, setButtonText ] = useState('Submit');
    const formik = useFormik({
        initialValues: {
            name: '',
            phone: '',
            email: '',
            body: ''
        },
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            fetch('/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
                body: encode({
                    'form-name': 'contact',
                    ...values,
                }),
            }).then(() => {
                setButtonText('Thanks!');
                resetForm();
            })
            .catch((err) => {
                console.error(err);
                alert('An error has occurred, please try again later.');
            })
        },
    });

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
            .join('&');
    };

    return (
        <form name="contact" className={[styles.form, variant ? styles.dark : ''].join(' ')} onSubmit={formik.handleSubmit} onReset={formik.handleReset} data-netlify="true" data-netlify-honeypot="second-name">
            <label htmlFor="second-name" className="hidden">Screen readers: Don't fill out second-name if you are a real human.</label>
            <input className="hidden" name="second-name" id="second-name" type="text" />
            <Input label="Name" type="text" name="name" id="name" variant={variant} {...formik.getFieldProps('name')} errors={formik.errors.name} />
            <Input label="Phone" type="text" name="phone" id="phone" variant={variant} {...formik.getFieldProps('phone')} errors={formik.errors.phone} />
            <Input label="Email Address" type="email" name="email" id="email" variant={variant} {...formik.getFieldProps('email')} errors={formik.errors.email} />
            <TextArea label="Message" name="body" id="body" variant={variant} {...formik.getFieldProps('body')} errors={formik.errors.body} />
            <Button className={styles.button} type="submit">{buttonText}</Button>
        </form>
    );
};

export default Form;
