import React from 'react';

import * as styles from '../../styles/modules/form-input.module.scss';

type InputProps = {
    label: string;
    errors: any;
    variant?: 'dark';
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input = ({ label, errors, name, variant, ...props }: InputProps) => {
    return (
        <div className={[styles.input, variant ? styles.dark : ''].join(' ')}>
            <label htmlFor={name}>{label}</label>
            <input name={name} {...props}  />
            {errors && <div className={styles.errors}>{errors}</div>}
        </div>
    );
};

export default Input;
