import React from 'react';

// Components
import ContactForm from '../form';

import * as styles from '../../styles/modules/contact-form-section.module.scss';

type ContactSectionProps = {
    children: React.ReactNode;
}

const ContactSection = ({ children }: ContactSectionProps) => {
    return (
        <div className={styles.contact}>
            <div className={styles.copy}>
                {children}
            </div>
            <div className={styles.form}>
                <ContactForm />
            </div>
        </div>
    );
};

export default ContactSection;
